import weatherapp from "../projectImages/weatherapp.png";
import lazyDevs from "../projectImages/lazyDevs.png";
import todos from "../projectImages/todo.jpg";
import converter from "../projectImages/converter.jpg";

export const PROJECTS = [
	{
		id: 0,
		name: "Lazy Dev's",
		description: {
			technologies: "html, css, javaScript, ReactJS, nodeJS, expressJS",
			description:
				"Team project i have created with my colleges. It is an authentication tool for developers. It will create for you all the authentication backend and front-end forms that are necessary for a user to log in, register, and log out from your website.",
		},
		githubLink: "https://github.com/Code-Generator-Sufaz/lazy-devs-v3",
		live: "https://lazydevs.onrender.com/",
		img: lazyDevs,
	},
	{
		id: 1,
		name: "Weather App",
		description: {
			technologies: "html, css, javaScript, ReactJS, nodeJS",
			description: "Weather app project done with React",
		},
		githubLink: "https://github.com/aurrelkondi/JS-Weather-App",
		live: "https://js-weather-app-two.vercel.app/",
		img: weatherapp,
	},
	{
		id: 3,
		name: "To-Do's",
		description: {
			technologies: "html, css, javaScript, ReactJS",
			description:
				"A app i created that gives you an option to create a to-do list. you will be able to group your todo's, edit, delete or mark as done.",
		},
		githubLink: "https://github.com/aurrelkondi/Todo-React",
		live: "https://todo-react-lyart-beta.vercel.app/",
		img: todos,
	},
];
