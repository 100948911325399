import { AiOutlineHtml5 } from "react-icons/ai";
import { DiSass, DiNodejsSmall } from "react-icons/di";
import { FaCss3, FaReact, FaAws } from "react-icons/fa";
import { TbBrandJavascript } from "react-icons/tb";
import { SiExpress, SiMongodb } from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
import { ImSpinner10 } from "react-icons/im";

export const aboutMe = [
	"My name is Aurrel and I am inspired to be a Frontend Engineer. I graduated in Economic Informatics in Albania and last year I finished a one-year class for web development at Digital Career Institute in Berlin.",
	"I moved to Germany in 2018 and had the chance to work at a few different places where I was able to achieve very satisfactory results and earned various experiences that will help me down the path of my career. My passion for technology made me pursue the boot camp where I learned a lot of technical skills. I have experience in JavaScript, Vanilla JS, React, Node.js, Express, MongoDB, and Git.",
	"During my time at boot camp, I have had the chance to work on a number of group projects where I was a good contributor to the team and made sure we met the deadlines. I really enjoy working in a team and I am eager to expand my knowledge by joining an experienced team. The final project that we are working on is a code generator built on the MERN stack that helps developers get ready to use sample code.",
	"I love teamwork, and I am open to learning new technologies. I am dedicated and want my code to be always optimized without compromising readability. I was so pleased to find your job advertisement as I believe I am well suited to the position you are hiring for. This job would give me the opportunity to use and develop my current skills.",
];

export const mySkills = [
	{
		name: "soft skills",
		skills: [
			{ name: "Team Player", level: "⭐⭐⭐⭐⭐" },
			{ name: "Life Time Lerner", level: "⭐⭐⭐⭐⭐" },
			{ name: "Costumer Care", level: "⭐⭐⭐⭐⭐" },
			{ name: "Creative", level: "⭐⭐⭐⭐⭐" },
			{ name: "Problem Solver", level: "⭐⭐⭐⭐⭐" },
			{ name: "Clear Mindset", level: "⭐⭐⭐⭐⭐" },
			{ name: "Hard Worker", level: "⭐⭐⭐⭐⭐" },
			{ name: "Organized", level: "⭐⭐⭐⭐⭐" },
		],
	},
	{
		name: "Hard Skills",
		skills: [
			{ name: "HTML", level: "⭐⭐⭐⭐⭐", icon: <AiOutlineHtml5 /> },
			{ name: "CSS", level: "⭐⭐⭐⭐⭐", icon: <FaCss3 /> },
			{ name: "SCSS", level: "⭐⭐⭐⭐⭐", icon: <DiSass /> },
			{ name: "Javascript", level: "⭐⭐⭐⭐", icon: <TbBrandJavascript /> },
			{ name: "Typescript", level: "⭐⭐⭐⭐", icon: <TbBrandJavascript /> },
			{ name: "nodeJS", level: "⭐⭐⭐⭐", icon: <DiNodejsSmall /> },
			{ name: "expressJS", level: "⭐⭐⭐⭐", icon: <SiExpress /> },
			{ name: "reactJS", level: "⭐⭐⭐⭐", icon: <FaReact /> },
			{ name: "nextJS", level: "⭐⭐⭐⭐", icon: <TbBrandNextjs /> },
			{ name: "mongoDB", level: "⭐⭐⭐⭐", icon: <SiMongodb /> },
			{ name: "CI/CD", level: "⭐⭐⭐⭐", icon: <ImSpinner10 /> },
			{ name: "AWS", level: "⭐⭐⭐⭐", icon: <FaAws /> },
		],
	},
];
